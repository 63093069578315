<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="order-detail-container nav-page-container">
      <div class="order-status-container">
        <div
            v-if="orderStatusIconName"
            class="status-icon-container"
        >
          <van-icon :name="orderStatusIconName"/>
        </div>
        <div class="status-text">
          {{ orderStatusText }}
        </div>
        <div v-if="orderStatusTip" class="status-tip">
          {{ orderStatusTip }}
        </div>
      </div>

      <goods-info
          class="order-item-card"
          view-model="small"
          :value="{
          goodsImgSrc:objectGetter(orderInfo,'linkGoods.tagIcon') || orderInfo.thumbImageUrl,
          goodsName: objectGetter(orderInfo,'linkGoods.goodsName'),
          goodsDesc: orderInfo.brandName,
          goodsPrice: orderInfo.amount,
          tagTitle: objectGetter(orderInfo,'linkGoods.tagTitle'),
        }"
          @click="$router.push({
          path: '/goodsDetail',
          query: getGoodsIdKeyValObj(),
        })"
      >
        <template #price-right>
          ×{{ orderInfo.num }}
        </template>
      </goods-info>

      <van-cell-group>
        <van-cell title="订单编号：" :value="orderInfo.orderNo"/>
        <van-cell title="下单时间：" :value="orderInfo.orderTime"/>
        <van-cell title="支付方式：" :value="payWayText"/>
        <van-cell title="支付时间：" :value="orderInfo.payTime"/>
      </van-cell-group>

      <van-cell-group>
        <van-cell title="配送方式：" :value="deliveryWayText"/>
        <van-cell title="收货人：" :value="orderInfo.revicerName"/>
        <van-cell title="收货人电话：" :value="orderInfo.revicerMobile"/>
        <van-cell title="收货地址：" :value="receiverAddress"/>
        <van-cell title="留言：" :value="orderInfo.memo"/>
      </van-cell-group>

      <!--
          <div
              v-if="isShowExpress && hasExpressInfo"
              class="order-express-info-container"
              :class="{ openExpressInfo }"
          >
            <express-info
                :order-id="orderInfo.id"
                :no-error-tip="true"
                @change="data => {
                  this.hasExpressInfo = !!data;
                }"
            />
            <div class="shrink" @click="openExpressInfo = !openExpressInfo">
              {{ openExpressInfo ? '收起' : '展开' }}
            </div>
          </div>
      -->


      <van-cell-group>
        <van-cell title="商品总额：" :value="'￥' + orderInfo.amount"/>
        <van-cell title="运费：" :value="'+￥' + (orderInfo.carriage || 0)"/>
        <div class="last-price-container">
          实付金额：
          <span class="last-price">{{ orderInfo.amount }}</span>
        </div>
      </van-cell-group>

      <div class="order-detail-bottom-bar">
        <!--      <van-button-->
        <!--          size="small" round plain color="#666"-->
        <!--          @click="() => $router.go(-2)"-->
        <!--      >返回首页-->
        <!--      </van-button>-->

        <van-button
            v-if="getBtnShow('取消订单', orderInfo.status)"
            size="small" round plain color="#666"
            @click="handleOnCancelOrder"
        >取消订单
        </van-button>

        <van-button
            v-if="getBtnShow('退换/售后', orderInfo.status)"
            size="small" round plain color="#666"
            @click="handleApplyForReturn"
        >退换/售后
        </van-button>

        <van-button
            v-if="getBtnShow('付款', orderInfo.status)"
            size="small" round type="primary"
            @click="handlePayment"
        >付款
        </van-button>

        <van-button
            v-if="getBtnShow('再次购买', orderInfo.status)"
            size="small" round plain type="primary"
            @click="handleBuyAgain"
        >再次购买
        </van-button>

        <van-button
            v-if="isShowFaPiao"
            size="small" round plain type="primary"
            @click="handleApplyInvoice"
        >申请发票
        </van-button>

        <van-button
            v-if="getBtnShow('确认收货', orderInfo.status)"
            size="small" round type="primary"
            @click="handleConfirmOrder"
        >确认收货
        </van-button>
      </div>


      <van-popup
          :value="replacePayDlg.show"
          :close-on-click-overlay="false"
          round position="bottom"
      >
        <van-image
            width="61.8%"
            style="margin: 0 19.1%"
            :src="replacePayDlg.payQrCodeUrl"
        />

        <p v-if="orderInfo.payWay === '0A'" style="text-align: center; color: #666">
          请用微信或支付宝扫描二维码，完成他人代付
        </p>
        <p v-if="orderInfo.payWay === '0B'" style="text-align: center; color: #666">
          请用微信扫描二维码，完成快捷代付
        </p>
        <div style="margin: 1.3em">
          <van-row gutter="20">
            <van-col span="12">
              <van-button round block type="primary" @click="backAndRefreshOrderList">
                支付完成
              </van-button>
            </van-col>
            <van-col span="12">
              <van-button round block @click="backAndRefreshOrderList">
                取消支付
              </van-button>
            </van-col>
          </van-row>
        </div>
      </van-popup>

      <fast-cards-pay
          :show.sync="fastCardsPayDlg.show"
          :order-id="fastCardsPayDlg.orderId"
          :closeable="false"
          @success="backAndRefreshOrderList"
          @cancel="backAndRefreshOrderList"
      />

    </page-container>
  </div>
</template>

<script>
import {
  cancelOrder,
  confirmOrder,
  getOrderInfoById, userApplyInvoice,
  userBalancePayment,
  normalOrderPayAgain,
  judgmentSpec
} from '@/api/normalOrder';
import {
  DENG_DAI_PIN_TUAN_WAN_CHENG,
  DAI_FU_KUAN,
  DAI_FA_HUO,
  DAI_SHOU_HUO,
  YI_WAN_CHENG,
  YI_GUAN_BI,
  WEI_QUAN_SHEN_QING,
  WEI_QUAN_WAN_CHENG,
  DING_DAN_QU_XIAO,
  ORDER_STATUS_MAP, CANG_KU_ZHONG, APPLY_FOR_RETURN_STATUS, CANG_KU_GUAN_BI,
} from '@/const/orderStatus';

import GoodsInfo from '@/components/GoodsInfo';
import { objectGetter } from '@/util';
import { getUserInfo } from '@/api/user';
import { Dialog, Toast } from 'vant';
import ExpressInfo from '@/components/ExpressInfo';
import { wxJsPay } from '@/util/wxJsPay';
import qrcode from 'qrcode';
import FastCardsPay from '@/components/FastCardsPay.vue';

export default {
  name: 'orderDetail',
  components: { FastCardsPay, ExpressInfo, GoodsInfo },
  data() {
    return {
      userInfo: {},
      orderInfo: {},
      openExpressInfo: false,
      hasExpressInfo: true,
      replacePayDlg: {
        show: false,
        payQrCodeUrl: '',
      },

      fastCardsPayDlg: {
        show: false,
        orderId: null,
      },
    };
  },
  computed: {
    isShowFaPiao() {
      let { orderInfo } = this;
      let fapiao = objectGetter(orderInfo, 'linkGoods.fapiao');
      return fapiao === '01';
    },
    // 店铺等级
    level() {
      return this.userInfo.level || '';
    },
    // 商品总额
    totalPrice() {
      let { orderInfo = {} } = this;
      let { stockAmount = 0, carriage = 0 } = orderInfo;
      return stockAmount - carriage;
    },
    // 订单状态icon名
    orderStatusIconName() {
      let { orderInfo: { status = '' } } = this;
      return {
        '00': 'friends-o', //<van-icon name="friends-o" />
        '01': 'gold-coin-o',
        '02': 'clock-o',
        '03': 'logistics',
        '04': 'certificate',
        '05': 'close',
        '06': 'revoke',
        '07': 'certificate',
        '08': 'clear',
        '09': 'wap-home',
      }[status] || '';
    },
    orderStatusText() {
      let { orderInfo: { status = '' } } = this;
      return ORDER_STATUS_MAP[status] || '';
    },
    // 订单状态提示信息
    orderStatusTip() {
      let { orderInfo: { status = '' } } = this;
      return {
        '00': '请耐心等待拼购完成',
        '01': '系统将自动取消订单，请尽快付款',
        '02': '即将为您发送订单',
        '03': '收到货后将自动确认订单',
        '04': '感谢您的购买,欢迎再次光临',
        '05': '订单已关闭,请前往商城再次购买',
        '06': '维权申请',
        '07': '维权已完成',
        '08': '',
      }[status] || '';
    },
    // 支付方式
    payWayText() {
      let { orderInfo: { payWay = '' } } = this;
      return {
        '01': '微信支付',
        '02': '支付宝支付',
        '03': '银联支付',
        '04': '余额支付',
        '0A': '他人代付',
        '06': '快捷支付',
        '0B': '快捷代付',
      }[payWay] || '';
    },
    // 配送方式
    deliveryWayText() {
      let { orderInfo: { deliveryWay = '' } } = this;
      return {
        '01': '快递',
      }[deliveryWay] || '其它';
    },
    // 收货地址
    receiverAddress() {
      let addressTextArr = [];
      let { orderInfo } = this;
      [
        'revicerProvince',
        'revicerCity',
        'revicerCountry',
        'revicerStreet',
        'revicerAddress',
      ].forEach(key => {
        orderInfo[key] && addressTextArr.push(orderInfo[key]);
      });

      return addressTextArr.join(' ');
    },
    // 是否能展示快递
    isShowExpress() {
      let { orderInfo: { status = '' } } = this;
      return [
        DAI_SHOU_HUO,
        YI_WAN_CHENG,
        YI_GUAN_BI,
        WEI_QUAN_SHEN_QING,
        WEI_QUAN_WAN_CHENG,
      ].includes(status);
    },
  },
  created() {
    getUserInfo().then(res => {
      this.userInfo = res || {};
    });

    getOrderInfoById({
      id: this.$route.query.orderId,
    }).then(res => {
      this.orderInfo = res || {};

      let isShowFaPiaoTip = objectGetter(res, 'linkGoods.fapiao') === '02';
      isShowFaPiaoTip && Dialog.alert({
        title: '需要开发票，请联系客服',
      });
    });
  },
  methods: {
    objectGetter,

    getBtnShow(btnName, status) {
      if (btnName === '取消订单') {
        return status === DAI_FU_KUAN;// || status === DAI_FA_HUO;
      }
      if (btnName === '付款') {
        return status === DAI_FU_KUAN;
      }
      if (btnName === '确认收货') {
        return status === DAI_SHOU_HUO;
      }
      if (btnName === '退换/售后') {
        return APPLY_FOR_RETURN_STATUS.includes(status);
      }
      if (btnName === '取消申诉') {
        return status === WEI_QUAN_SHEN_QING;
      }
      if (btnName === '再次购买') {
        return status !== DENG_DAI_PIN_TUAN_WAN_CHENG && status !== DAI_FU_KUAN;
      }
      return false;
    },

    // 代付
    replacePay() {
      const name = this.orderInfo.payWay === '0A' ? '他人代付' : '快捷代付';
      let toast = Toast.loading({
        message: `${name}中...`,
        forbidClick: true,
      });
      normalOrderPayAgain({ id: this.orderInfo.id }, { headers: { getAllResponse: true } }).then(res => {
        toast.clear();
        let { code, data, msg } = objectGetter(res, 'data') || {};
        let isPaySuccess = code === 0;
        if (isPaySuccess) {
          qrcode.toDataURL(data.payUrl || '无效的二维码，payUrl is undefined', (err, payQrCodeUrl) => {
            Object.assign(this.replacePayDlg, {
              show: true,
              payQrCodeUrl,
            });
          });
        } else {
          Toast(msg || '支付失败.');
        }
      });

    },
    // 付款
    handlePayment() {
      let { orderInfo, userInfo } = this;

      // if (userInfo.status === '01') {
      //   Dialog.alert({
      //     message: `请先完善个人信息，再购买商品`,
      //     confirmButtonText: '去完善',
      //   }).then(() => {
      //     this.$router.replace('/userInfo');
      //   });
      //   return;
      // }

      Dialog.confirm({
        title: `是否确认付款`,
        // message: '弹窗内容',
        confirmButtonText: '确认',
        beforeClose: (action, done) => {
          // 点击确认支付
          if (action === 'confirm') {

            // 微信支付
            if (orderInfo.payWay === '01') {
              // TODO: 微信支付
              // Toast('微信支付开发中');
              wxJsPay(this.orderInfo, 'normalOrderPayAgain').then(res => {
                if (res) {
                  Toast('微信支付成功');
                  this.backAndRefreshOrderList();
                }
              });
              done();
            }

            // 余额支付
            if (orderInfo.payWay === '04') {
              let toast = Toast.loading({
                message: '余额支付中...',
                forbidClick: true,
              });
              done();
              userBalancePayment({ id: orderInfo.id }, { headers: { getAllResponse: true } }).then(res => {
                // toast.clear();
                let { code, msg } = objectGetter(res, 'data') || {};
                let isPaySuccess = code === 0;
                if (isPaySuccess) {
                  Toast('余额支付成功');
                  this.backAndRefreshOrderList();
                } else {
                  Toast(msg || '支付失败.');
                }
              });
            }

            // 代付
            if (['0A', '0B'].includes(orderInfo.payWay)) {
              done();
              this.replacePay();
            }

            // 快捷支付
            if (orderInfo.payWay === '06') {
              done();
              Object.assign(this.fastCardsPayDlg, {
                show: true,
                orderId: this.orderInfo.id,
              });
            }
          } else {
            // 点击取消
            done();
          }
        },
      }).catch(() => {
      });
    },
    // 取消订单
    handleOnCancelOrder() {
      let { linkGoods = {} } = this.orderInfo;
      Dialog.confirm({
        title: `确认取消订单么？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            cancelOrder({ orderId: this.orderInfo.id }).then(res => {
              if (res === true) {
                this.backAndRefreshOrderList();
                done();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
    // 维权/售后
    handleApplyForReturn() {
      this.$router.push({
        path: '/applyForReturn',
        query: {
          orderId: this.orderInfo.id,
        }
      });
    },
    getGoodsIdKeyValObj() {
      let { orderType, linkGoods = {} } = this.orderInfo || {};
      let goodsIdKey = { '01': 'normalGoodsId', '02': 'groupGoodsId' }[orderType];
      let goodsId = linkGoods.goodsId;
      return { [goodsIdKey]: goodsId };
    },
    // 再次购买
    handleBuyAgain() {
      const { count, tagIcon, tagTitle, specIdList, tagId } = this.orderInfo.linkGoods;
      let specificationsObj = {
        tagIcon,
        tagTitle,
        specIdList,
        count
      };
      judgmentSpec({ tagId }).then((res) => {
        if (res) {
          this.$store.commit('payment/setRefreshing', true);
          this.$router.push({
            path: '/payment',
            query: { ...this.getGoodsIdKeyValObj(), specificationsObj: JSON.stringify(specificationsObj) },
          });
        } else {
          this.$router.push({
            path: '/goodsDetail',
            query: this.getGoodsIdKeyValObj(),
          });
        }
      });

    },
    // 申请发票
    handleApplyInvoice() {
      let { orderInfo } = this;
      userApplyInvoice(orderInfo.id).then(res => {
        let { code, msg } = objectGetter(res, 'data.data') || {};
        if (code === 0) {
          Toast('您已成功申请发票');
        } else {
          Toast(msg || '申请发票失败');
        }
      });
    },
    // 确认收货
    handleConfirmOrder() {
      let { orderInfo } = this;
      Dialog.confirm({
        title: `是否确认收货？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            confirmOrder({ orderId: orderInfo.id }).then(res => {
              if (res === true) {
                this.backAndRefreshOrderList();
                done();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },

    backAndRefreshOrderList() {
      this.$store.commit('orderList/setRefreshing', true);
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
@icon-container-width: 50px;

.order-detail-container {
  padding: 0 0 calc(64px + constant(safe-area-inset-bottom)) 0;
  padding: 0 0 calc(64px + env(safe-area-inset-bottom)) 0;

  .order-status-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    padding: 0 15px 0 @icon-container-width;
    height: 80px;
    color: #fff;
    background: linear-gradient(to right, #ff9c57, #ff7c1b);

    .status-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: @icon-container-width;
      height: 100%;

      .van-icon {
        font-size: 24px;
      }
    }

    .status-text {
      line-height: 1;
      font-size: 18px;
      font-weight: bolder;
    }

    .status-tip {
      margin-top: 7px;
      line-height: 1;
      font-size: 12px;
    }
  }

  .order-item-card {
    margin: 10px;
    padding: 10px;
    border-radius: 15px;
    background-color: #fff;
  }

  .van-cell-group + .van-cell-group {
    margin-top: 10px;
  }

  .order-express-info-container {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 10px 10px 30px 10px;
    max-height: 188px;
    overflow: hidden;
    background-color: #fff;

    .shrink {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      background-color: #fff;
      color: #666;
    }

    &.openExpressInfo {
      max-height: none;
    }
  }

  .last-price-container {
    padding: 25px 15px 15px 15px;
    text-align: right;

    .last-price {
      font-size: 24px;
      font-weight: bolder;
      color: #e93b3d;
      white-space: nowrap;
      overflow-x: auto;

      &:before {
        content: '¥ ';
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .order-detail-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0 contant(safe-area-inset-bottom) 0;
    padding: 0 0 env(safe-area-inset-bottom) 0;
    width: 100%;
    height: 54px;
    background-color: #fff;

    button {
      padding: 0 12px;
      font-size: 14px;
      margin: 0 10px;
    }
  }

}
</style>
